.sidebar {
  float: left;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  padding: 24px;
  width: 320px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.container-page {
  margin-left: 320px;
  padding: 24px;
}

/* https://stackoverflow.com/questions/35060594/react-bootstrap-multiple-modal */
div[role='dialog'][aria-modal='true']:nth-last-child(1) {
  z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}
div[role='dialog'][aria-modal='true']:nth-last-child(3) {
  z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}
div[role='dialog'][aria-modal='true']:nth-last-child(5) {
  z-index: 1025;
}
.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}

.order-selector {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  flex-flow: row wrap;
  overflow-y: scroll;
}

.order {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.1rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.9rem;
}

.order:hover {
  background-color: #e2e6ea;
}

.order.occupied {
  background-color: #6c757d;
  color: white;
}

.order.selected {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: white;
}

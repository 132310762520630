@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.competition-nav {
  border-radius: 0.25rem;
}

.competition-nav .nav-link {
  color: #343a40;
  padding: 0.4rem 0.8rem;
  margin-right: 0.1rem;
}

.competition-nav .nav-link:hover {
  color: #000;
  text-decoration: underline;
}

.competition-nav .nav-link.active {
  text-decoration: none;
  background-color: #343a40;
  color: #fff;
}

.card-competition {
  transition: all 0.25s;
  text-decoration: none !important;
}

.card-competition:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.order-selector {
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  flex-flow: row wrap;
  overflow-y: scroll;
}

.order {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.1rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.9rem;
}

.order:hover {
  background-color: #e2e6ea;
}

.order.occupied {
  background-color: #6c757d;
  color: white;
}

.order.selected {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: white;
}

.sidebar {
  float: left;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  padding: 24px;
  width: 320px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.container-page {
  margin-left: 320px;
  padding: 24px;
}

/* https://stackoverflow.com/questions/35060594/react-bootstrap-multiple-modal */
div[role='dialog'][aria-modal='true']:nth-last-child(1) {
  z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}
div[role='dialog'][aria-modal='true']:nth-last-child(3) {
  z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}
div[role='dialog'][aria-modal='true']:nth-last-child(5) {
  z-index: 1025;
}
.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}

